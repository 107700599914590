var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import RichEditor from "~/components/common/rich-editor.vue";
import { Emit } from "vue-property-decorator";
import { Layout, Dependencies } from "~/core/decorator";
import { NoticeService } from "~/services/manage-service/notice.service";
// 工具栏配置
var toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    // [{ direction: "rtl" }], // 文本方向
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
    ["link", "image", "video"] // 链接、图片、视频
];
var AddBulletin = /** @class */ (function (_super) {
    __extends(AddBulletin, _super);
    function AddBulletin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editorOption = {
            theme: "snow",
            placeholder: "您想说点什么？",
            modules: {
                toolbar: {
                    container: toolbarOptions,
                    // container: "#toolbar",
                    handlers: {}
                }
            }
        };
        _this.bulletionModel = {
            title: "",
            type: "",
            content: ""
        };
        _this.dialogImageUrl = "";
        _this.dialogVisible = false;
        _this.rules = {
            type: [{ required: true, message: "请选择公告类型", trigger: "change" }],
            title: [{ required: true, message: "请选择标题", trigger: "blur" }]
        };
        return _this;
    }
    AddBulletin.prototype.close = function () {
        this.$router.push("/assist-work/bulletin-manage");
    };
    AddBulletin.prototype.onSubmit = function () {
        var _this = this;
        var form = this.$refs["form"];
        if (form) {
            form.validate(function (valid) {
                if (!valid)
                    return;
                _this.noticeService.addNotice(_this.bulletionModel).subscribe(function (data) {
                    _this.$message.success("发布成功");
                    _this.close();
                });
            });
        }
    };
    AddBulletin.prototype.reset = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    __decorate([
        Dependencies(NoticeService)
    ], AddBulletin.prototype, "noticeService", void 0);
    __decorate([
        Emit("close")
    ], AddBulletin.prototype, "close", null);
    AddBulletin = __decorate([
        Layout("workspace"),
        Component({
            components: {
                RichEditor: RichEditor
            }
        })
    ], AddBulletin);
    return AddBulletin;
}(Vue));
export default AddBulletin;
