var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Vue from "vue";
import Component from "vue-class-component";
import { quillEditor } from "vue-quill-editor";
import { Prop } from "vue-property-decorator";
var RichEditor = /** @class */ (function (_super) {
    __extends(RichEditor, _super);
    function RichEditor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RichEditor.prototype.created = function () { };
    RichEditor.prototype.mounted = function () {
    };
    RichEditor.prototype.handleInput = function ($event) {
        this.$emit("input", $event.html); // key code
    };
    __decorate([
        Prop({ default: "" })
    ], RichEditor.prototype, "value", void 0);
    __decorate([
        Prop({
            default: false,
            type: Boolean
        })
    ], RichEditor.prototype, "disabled", void 0);
    __decorate([
        Prop({
            default: {}
        })
    ], RichEditor.prototype, "editorOption", void 0);
    __decorate([
        Prop()
    ], RichEditor.prototype, "created", null);
    RichEditor = __decorate([
        Component({
            components: {
                quillEditor: quillEditor
            }
        })
    ], RichEditor);
    return RichEditor;
}(Vue));
export default RichEditor;
